/* Manually adding pre-flight until we move to tailwind */
.pseudo-body {
  h1,
  h2,
  p {
    margin: 0;
  }
}

.pseudo-body {
  h1,
  h2 {
    font-size: inherit;
    font-weight: inherit;
  }
}

.pseudo-body {
  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

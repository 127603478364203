:root {
  --color-body: #0e0d0c;
  --color-gray: #78716c;
  --color-red: #ff6456;
}

@font-face {
  font-family: 'Modern Era';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/modern_era_regular.otf') format('opentype');
}

@font-face {
  font-family: 'Modern Era Bold';
  font-style: bold;
  font-weight: 700;
  src: url('../assets/fonts/modern_era_bold.otf') format('opentype');
}

.pseudo-body {
  height: 100vh;
  color: var(--color-body);
  font-family: 'Modern Era', 'ui-sans-serif', 'system-ui',
  '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto',
  'Helvetica Neue', 'Arial', 'Noto Sans', 'sans-serif', 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

  .header {
    font-family: 'Modern Era Bold';
    font-size: 40px;
    line-height: 44px;
    letter-spacing: -1.9199999570846558px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .entry-page-body {
    line-height: 19.2px;
    letter-spacing: -0.015em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .continue-body {
    font-size: 18px;
    line-height: 21.6px;
    letter-spacing: -0.015em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .gray-body {
    line-height: 19.2px;
    letter-spacing: -0.015em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--color-gray);
  }

  .list-item-header {
    font-family: 'Modern Era Bold';
    font-size: 14px;
    font-weight: 700;
    line-height: 16.8px;
    letter-spacing: -0.015em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .list-item-body {
    font-size: 14px;
    line-height: 16.8px;
    letter-spacing: -0.015em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--color-gray);
  }

  .body-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    max-height: 100vh;
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .button {
    width: 100%;
    min-width: 120px;
    max-width: 100vw;
    height: 60px;
    padding: 8px 24px;
    border-radius: 999px;
    background-color: var(--color-body);
    color: #fff;
    font-family: 'Modern Era Bold', sans-serif;
    font-weight: 700;
    font-size: 1em;
    cursor: pointer;
  }

  .text-\[40\] {
    font-size: 2.5em;
    line-height: 2.5rem;
  }

  .text-sm {
    font-size: 0.875em;
    line-height: 1.25rem;
  }

  .text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .flex {
    display: flex;
  }

  .items-start {
    align-items: flex-start;
  }

  .items-center {
    align-items: center;
  }

  .justify-center {
    justify-content: center;
  }

  .w-full {
    width: 100%;
  }

  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .flex-col {
    flex-direction: column;
  }

  .gap-10 {
    gap: 10px;
  }

  .gap-14 {
    gap: 14px;
  }

  .mb-4 {
    margin-bottom: 1rem;
  }

  .mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .mb-6 {
    margin-bottom: 1.5rem;
  }

  .mb-7 {
    margin-bottom: 1.75rem;
  }

  .mb-8 {
    margin-bottom: 2rem;
  }

  .mb-10 {
    margin-bottom: 2.5rem;
  }

  .p-4 {
    padding: 1rem;
  }

  .pb-16 {
    padding-bottom: 4rem;
  }

  .pt-1 {
    padding-top: 0.25rem;
  }

  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .bottom-border {
    border-color: #d6d3d1;
    border-style: solid;
    border-width: 0 0 1px 0;
  }

  .image {
    width: 100%;
  }

  .body-container > .spotlight {
    width: unset;
    margin: auto;
    border-radius: unset;
    height: 100vh;
  }

  .image-header {
    background: linear-gradient(
      0deg,
      rgba(255, 120, 68, 0.24) 0%,
      rgba(255, 120, 68, 0) 100%
    );
    height: 80px;
    margin: 0;
    margin-bottom: -20px;
  }

  /* Input Field */

  .input-container {
    gap: 0.5rem;

    label {
      margin-bottom: 0.5rem;
      letter-spacing: -0.24px;
    }

    input[type='email'] {
      width: 100%;
      max-width: 380px;
      padding: 1rem;
      background-color: #fff;
      border: 1px solid #d6d3d1;
      border-radius: 8px;
      font-size: 1rem;
      color: #333;
      box-sizing: border-box;
    }

    input[type='email']::placeholder {
      color: #bbb;
    }

    input[type='email']:invalid.dirty {
      border: 2px solid var(--color-red);
    }
  }

  .checkbox-container {
    text-align: left;
    max-width: 400px;

    label {
      display: flex;
    }

    .label {
      font-size: 14px;
      line-height: 16.8px;
      letter-spacing: -0.015em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: var(--color-gray);
    }

    input[type='checkbox'] {
      width: 0;
      height: 0;
    }

    .checkbox-image {
      min-width: fit-content;
    }

    input[type='checkbox'] + div > .checkbox-image {
      stroke: #d6d3d1;
    }

    input[type='checkbox']:focus + div > .checkbox-image {
      outline: -webkit-focus-ring-color auto 5px;
    }

    input[type='checkbox']:invalid:not(:focus).dirty + div > .checkbox-image {
      stroke-width: 2px;
      stroke: var(--color-red);
    }
  }
}

@media (min-width: 640px) {
  .pseudo-body {

    .body-container {
      justify-content: center;
    }

    .body-container > div {
      max-width: 700px;
    }

    .body-container > .spotlight {
      padding-top: unset;
      width: fit-content;
      border-radius: 45px;
      width: 50%;
      max-width: 700px;
      height: unset;
    }

    .image-header {
      display: none;
    }

    .button {
      max-width: 300px;
    }
  }
}

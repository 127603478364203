body, html {
    height: 100%;
    margin: 0;
    font-family: 'Arial', sans-serif; /* Replace with the font of your choice */
    color: #000000; /* (202, 17, 17, 0.828); */
    background: linear-gradient(to top, #FF5D05, #FFEDDB); /* Assuming a black background PP Colour is 26B1E6*/
  }
  
  /* Full-screen container */
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
 /*   background-image: url('your-background-pattern.png'); /* Replace with your background pattern image */
    background-size: cover;
    background-repeat: no-repeat;
    margin: 1rem;
  }
  
  .spaced {
    margin-top: 10px; /* Adjust the value as per your needs */
  }
  
  /* Header text */
  .header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .header .title {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
  }
  
  .header .subtitle {
    font-size: 1rem;
  }
  
  /* Points display */
  .points-display {
    background-color:   #F1E7B0; /* rgba(255, 255, 255, 0.1) Semi-transparent background */
    border-radius: 50%; /* Circular shape */
    width: 200px; /* Circle size */
    height: 200px; /* Circle size */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1rem;
    color: #000000;
  }

  .submit-email-button:disabled {
    color: grey; /* Change the text color when disabled */
  }
  
  .points-display .points {
    font-size: 3rem; /* Large font for points */
    font-weight: bold;
  }

  .points-display .points2 {
    font-size: 2rem; /* Large font for points */
    font-weight: bold;
  }
  
  .points-display .points-label {
    font-size: 1rem;
    text-align: center;
  }
  
  /* Progress bar (assuming you use a library or custom component for this) */
  .progress-bar {
    width: 80%; /* Full-width progress bar */
    height: 20px; /* Thickness of the progress bar */
    background-color: #444; /* Background of the progress bar */
    border-radius: 10px; /* Rounded corners */
    margin-bottom: 1rem;
    /* Progress bar filler styles would be handled by your progress bar component */
  }
  
  /* Redeem button */
  .redeem-button {
    background-color: #fff; /* White background for the button */
    color: #000; /* Black text to stand out against the white background */
    border: none;
    padding: 1rem 2rem;
    font-size: 1rem;
    border-radius: 20px; /* Rounded corners */
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .redeem-button:hover {
    background-color: #f2f2f2; /* Slightly different color on hover for feedback */
  }
  
  /* Style your email form field */
.email-form-field {
  margin: 20px 0; /* Spacing above and below the form field */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center the input field horizontally */
  align-items: center;
}

/* Style the input element for email */
input[type="email"] {
  width: 80%; /* Adjust the width as needed */
  max-width: 380px;
  padding: 15px; /* Padding for the input text */
  background-color: #fff; /* White background for the input */
  border: 1px solid #ddd; /* Light grey border */
  border-radius: 25px; /* Curved corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  font-size: 1rem; /* Font size similar to the rest of your design */
  color: #333; /* Text color */
}

/* Ensure the placeholder text matches the design */
input[type="email"]::placeholder {
  color: #bbb; /* Lighter text color for placeholder */
}

/* Style the button for submitting the email */
.submit-email-button {
  width: 90%;
  max-width: 400px;
  padding: 15px; /* Padding to make the button larger */
  background-color: #000000; /* Black background to stand out */
  color: #fff; /* White text color */
  border: none;
  border-radius: 25px; /* Curved corners */
  cursor: pointer;
  transition: background-color 0.2s; /* Smooth transition for hover effect */
  font-size: 1rem; /* Font size similar to the input */
  margin-top: 20px;

}

/* Hover effect for the button */
.submit-email-button:hover {
  background-color: #444; /* Darker shade when hovered */
}

  /* Add styles for the form container if not already present */
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Apply this class to the container wrapping the email input and button */
.form-container {
  text-align: center; /* Center the text for any text inside the container */
  padding: 1rem;
  width: 100%; /* Full width */
}

.logo-box {
  /* background-color: #fff; /* White background */
 /* border-radius: 10px; /* Rounded corners */
  max-width: 400px;
  padding: 20px; /* Padding around the content */
 /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Adds a subtle shadow for depth */
  width: 80%; /* Fits to the content width */
  margin: 20px auto; /* Centers the box and adds margin to the top and bottom */
  display: flex; /* Use flex to center the logo image if needed */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.logo-image {
  max-width: 100%; /* Ensures the image is responsive and fits within the box */
  height: auto; /* Maintains aspect ratio */
}

.hero-image {
  max-width: 200px; /* Ensures the image is responsive and fits within the box */
  height: auto; /* Maintains aspect ratio */
}

.card-box {
  /* background-color: #fff; /* White background */
  border-radius: 10px; /* Rounded corners */
  max-width: 400px;
  padding: 20px; /* Padding around the content */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional: Adds a subtle shadow for depth */
  width: 80%; /* Fits to the content width */
  margin: 20px auto; /* Centers the box and adds margin to the top and bottom */
  display: flex; /* Use flex to center the logo image if needed */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  cursor: pointer;
  background-color: #ffffff;
}

.card-box:hover {
  background-color: #ffffff;
  color: #000000;
}

.selected {
  border-color: blue; /* Change as needed */
  background-color: #ff5d0565; /* Change as needed */
  color: #000000;

}

.card-box-container {
  margin-top: 60px;
}

.highlight-text {
 color:   #F1E7B0; 
}